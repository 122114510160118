@import './styles-animations.scss';
@import './styles-config.scss';
@import './styles-fonts.scss';

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

* {
  overflow-y: hidden;
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: Ubuntu, 'times new roman', times, serif;
}

*:focus {
  outline: none !important;
}

.invisible {
  opacity: 0;
}
