@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('./../assets/fonts/Ubuntu/ubuntu-v15-latin-regular.eot');
  src: local(''),
    url('./../assets/fonts/Ubuntu/ubuntu-v15-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    url('./../assets/fonts/Ubuntu/ubuntu-v15-latin-regular.woff2')
      format('woff2'),
    url('./../assets/fonts/Ubuntu/ubuntu-v15-latin-regular.woff') format('woff'),
    url('./../assets/fonts/Ubuntu/ubuntu-v15-latin-regular.ttf')
      format('truetype'),
    url('./../assets/fonts/Ubuntu/ubuntu-v15-latin-regular.svg#Ubuntu')
      format('svg');
}
